import { Time } from "@angular/common";

export class ShiftDefinition{

  sh_id!:any;
  // shift_name!: any;
  sh_starttime!:any
  sh_category_id
  sh_area_id
  sh_category
  sh_endtime
  sh_duration
  sh_created_by!:any
  sh_name
userid
sh_include_exclude
  // sh_name
}
