import { Component, OnInit } from '@angular/core';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss'],
})
export class DashboardComponent implements OnInit {

  constructor(public navCtrl: NavController,) { }

  ngOnInit() {}
  test(){
    this.navCtrl.navigateForward('dashboard/test')
  }
}
