export class WorkPattern{

 day1
 day2
 day3
 day4
 day5


}
