export class WorkDay{

  startTime:string;
  sunDay!: number;
  monDay!:number;
  tueDay!:number;
  wedDay!:number;
  thuDay!:number;
  friDay!:number;
  satDay!:number;




}
